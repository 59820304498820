body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

p:has(> .popout-image:not(.inline-image)) {
  margin-left: calc((-100vw + 100%) / 2);
  width: 100vw;

  @media (min-width: 768px) {
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
  }

  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
}

.popout-image:not(.inline-image) {
  margin: auto;
  width: 1080px;
  max-width: 100%;
}

.sponsor-card,
.keynote-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-in-out;
}

.sponsor-card:hover,
.keynote-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

table a.text-blue-500 {
  color: theme("colors.blue.300");
}

.maplibregl-cooperative-gesture-screen {
  z-index: 10 !important;
}
